export const WAD = 1000000000000000000n;

function log2(x: bigint) {
  if (x <= 0n) throw new Error('UNDEFINED');

  let r = BigInt(0xffffffffffffffffffffffffffffffffn < x) << 7n;
  r |= BigInt(0xffffffffffffffffn < x >> r) << 6n;
  r |= BigInt(0xffffffffn < x >> r) << 5n;
  r |= BigInt(0xffffn < x >> r) << 4n;
  r |= BigInt(0xffn < x >> r) << 3n;
  r |= BigInt(0xfn < x >> r) << 2n;
  r |= BigInt(0x3n < x >> r) << 1n;
  r |= BigInt(0x1n < x >> r);

  return r;
}

export function lnWad(x: bigint) {
  if (x <= 0n) throw new Error('UNDEFINED');

  const k = log2(x) - 96n;
  x <<= 159n - k;
  x >>= 159n;

  let p = x + 3273285459638523848632254066296n;
  p = ((p * x) >> 96n) + 24828157081833163892658089445524n;
  p = ((p * x) >> 96n) + 43456485725739037958740375743393n;
  p = ((p * x) >> 96n) - 11111509109440967052023855526967n;
  p = ((p * x) >> 96n) - 45023709667254063763336534515857n;
  p = ((p * x) >> 96n) - 14706773417378608786704636184526n;
  p = p * x - (795164235651350426258249787498n << 96n);

  let q = x + 5573035233440673466300451813936n;
  q = ((q * x) >> 96n) + 71694874799317883764090561454958n;
  q = ((q * x) >> 96n) + 283447036172924575727196451306956n;
  q = ((q * x) >> 96n) + 401686690394027663651624208769553n;
  q = ((q * x) >> 96n) + 204048457590392012362485061816622n;
  q = ((q * x) >> 96n) + 31853899698501571402653359427138n;
  q = ((q * x) >> 96n) + 909429971244387300277376558375n;

  let r = p / q;

  r *= 1677202110996718588342820967067443963516166n;
  r += 16597577552685614221487285958193947469193820559219878177908093499208371n * k;
  r += 600920179829731861736702779321621459595472258049074101567377883020018308n;
  r >>= 174n;

  return r;
}
